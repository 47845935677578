export default [
  {
    name: 'tab',
    config: {
      title: 'Heizsystem',
      links: [
        {
          name: 'Übersicht',
          icon: 'heart',
          active: false,
          page: 'overview',
          to: '?page=overview',
        },
        {
          name: 'Überwachung',
          icon: 'tachometer',
          solid: true,
          page: 'monitoring',
          active: false,
          to: '?page=monitoring',
        },
        {
          name: 'Optimierung',
          icon: 'line-chart',
          page: 'optimization',
          active: false,
          to: '?page=optimization',
        },
        {
          name: 'Steuerung',
          icon: 'cog',
          active: false,
          page: 'control',
          restrictedTo: 'Alpha-Feature',
          to: '?page=control',
        },
        {
          name: 'Komponenten',
          icon: 'puzzle',
          solid: true,
          active: false,
          restrictedTo: 'Heizungs-Betrieb',
          page: 'components',
          to: '?page=components',
        },
        {
          name: 'Schema',
          solid: true,
          icon: 'hot',
          active: false,
          restrictedTo: 'ARIGO-access-role',
          page: 'arigoSchema',
          to: '?page=arigoSchema',
        },
        {
          name: 'Analytics',
          solid: true,
          icon: 'file',
          active: false,
          restrictedTo: 'ARIGO-access-role',
          page: 'arigoAnalytics',
          to: '?page=arigoAnalytics',
        },
        {
          name: 'Meldungen',
          solid: true,
          icon: 'bell',
          active: false,
          restrictedTo: 'ARIGO-access-role',
          page: 'arigoAlerts',
          to: '?page=arigoAlerts',
        },
        {
          name: 'Energiereport',
          icon: 'line-chart',
          active: false,
          restrictedTo: 'ARIGO-report-role',
          page: 'arigoReport',
          to: '?page=arigoReport',
        },

        /*
    {
      name: 'Alarm',
      icon: 'bell',
      active: false,
      restrictedTo: 'Alpha-Feature',
      to: this.$route.fullPath,
    },
        {
          name: 'Chronik',
          icon: 'history',
          page: 'chronicle',
          active: false,
          restrictedTo: 'Alpha-Feature',
          to: '?page=chronicle',
        },
        {
          name: 'Contracting',
          icon: 'building',
          page: 'contracting',
          active: false,
          restrictedTo: 'Alpha-Feature',
          to: '?page=contracting',
        }, */
      ],
    },
  },
];
